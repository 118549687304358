import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Row, Col, Container, ListGroup, Carousel } from "react-bootstrap"

const AboutPage = ({data}) => (
  <Layout pageInfo={{ pageName: "about" }} active="about">
    <SEO title="About" keywords={[`unhistoried`, `about`, `brrrlabs`]} />
    <Container className="about-page">
      <Row noGutters className="unhistoried-about-row">
        <Col md={4} className="unhistoried-about-col-img">
          <GatsbyImage image={data.contentfulAbout.featuredImage.gatsbyImageData}  />
        </Col>
        <Col md={8} className="unhistoried-about-col">
          <div dangerouslySetInnerHTML={{ __html: data.contentfulAbout.aboutParagraphs.childMarkdownRemark.html.replace(/href/g, "target='_blank' href") }}></div>
          <div dangerouslySetInnerHTML={{ __html: data.contentfulGeneral.copyrightParagraph.childMarkdownRemark.html.replace(/href/g, "target='_blank' href") }}></div>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export const aboutQuery = graphql`
query AboutQuery {
  contentfulGeneral(contentful_id: {eq: "1fDxT0SD7yWPCDmLFmmaAx"}) {
    copyrightParagraph{
      childMarkdownRemark {
        html
      }
    }
  }
  contentfulAbout(contentful_id: {eq: "5nkApj4cR0KIDRnRSzavAX"}) {
    featuredImage {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
      file {
        fileName
      }
    }
    aboutParagraphs {
      childMarkdownRemark {
        html
      }
    }
  }
}`

export default AboutPage
